<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="[
        'ตั้งค่า',
        {
          name: 'ชนิดหวย',
          action: () => {
            $router.push('/setting/lotto-type')
          },
        },
        id ? 'แก้ไข' : 'เพิ่ม',
      ]"
    />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        {{ id ? "แก้ไขชนิดหวย" : "เพิ่มชนิดหวย" }}
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="submitForm"
          novalidate
        >
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3"> #ID: </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  {{ form.id }}
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="lotto-group"
                  class="col-12 col-sm-3 col-md-4 col-lg-3"
                >
                  กลุ่ม:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <select
                      class="form-control"
                      id="lotto-group"
                      v-model="form.huayGroupId"
                    >
                      <option
                        :value="item.id"
                        v-for="item in lottoGroups"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="lotto-type-name"
                  class="col-12 col-sm-3 col-md-4 col-lg-3"
                >
                  ชื่อ:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <input
                      id="lotto-type-name"
                      type="text"
                      class="form-control mr-1"
                      v-model="form.name"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="showOrder"
                  class="col-12 col-sm-3 col-md-4 col-lg-3"
                >
                  ลำดับการแสดง:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <input
                      id="showOrder"
                      type="text"
                      class="form-control mr-1"
                      v-model.number="form.showOrder"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  สถานะ:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <select v-model="form.status" class="form-control">
                      <option value="OPEN">เปิด</option>
                      <option value="CLOSE">ปิด</option>
                    </select>
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  เริ่มเวลา:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <b-form-timepicker
                      locale="th"
                      v-model="form.openTime"
                      placeholder="เลือกเวลา"
                      now-button
                      reset-button
                      label-close-button="ปิด"
                      label-no-time-selected="ยังไม่เลือก"
                      label-reset-button="รีเซ็ต"
                      label-now-button="ตอนนี้"
                    ></b-form-timepicker>
                    <input type="text" hidden v-model="form.openTime" />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  ปิดเวลา:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <b-form-timepicker
                      locale="th"
                      v-model="form.closeTime"
                      placeholder="เลือกเวลา"
                      now-button
                      reset-button
                      label-close-button="ปิด"
                      label-no-time-selected="ยังไม่เลือก"
                      label-reset-button="รีเซ็ต"
                      label-now-button="ตอนนี้"
                    ></b-form-timepicker>
                    <input type="text" hidden v-model="form.closeTime" />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  รูปภาพ:
                </label>
                <div class="col-12 col-sm-9 col-md-8 col-lg-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.image"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">icon:</label>
                <div class="col-12 col-sm-9 col-md-8 col-lg-9">
                  <input type="text" class="form-control" v-model="form.icon" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  เป็นสลาก:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input cursor-pointer"
                      style="font-size: 36px"
                      v-model="form.isSalak"
                    />
                    <label for="" class="form-check-label"></label>
                  </div>
                </div>
              </div>
              <div v-if="form.isSalak" class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3"
                  >จำนวนวันที่เปิดขาย:</label
                >
                <div class="col-12 col-sm-9 col-md-8 col-lg-9">
                  <ValidationProvider rules="required" v-slot="v">
                    <input
                      type="number"
                      class="form-control"
                      v-model.number="form.salakSellingDays"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  หลายรอบต่อวัน:
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input cursor-pointer"
                      style="font-size: 36px"
                      v-model="form.multiRoundPerDay"
                    />
                    <label for="" class="form-check-label"></label>
                  </div>
                </div>
              </div>
              <div class="form-group row" v-if="form.multiRoundPerDay">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  รอบละ(นาที):
                </label>
                <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
                  <ValidationProvider rules="numeric" v-slot="v">
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.everyMinute"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  ประเภท
                </label>
                <div class="col-12 col-sm-9 col-md-8 col-lg-9">
                  <div class="form-group row">
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                      v-for="item in form.huayCategoryTypes"
                      :key="item.id"
                    >
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input cursor-pointer"
                          :id="`lotto-category-${item.huayCategoryId}`"
                          v-model="item.isOpen"
                        />
                        <label
                          :for="`lotto-category-${item.huayCategoryId}`"
                          class="form-check-label ml-1 cursor-pointer"
                        >
                          {{ item.huayCategoryName }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-md-4 col-lg-3">
                  วันเปิด
                </label>
                <div class="col-12 col-sm-9 col-md-8 col-lg-9">
                  <div class="form-group row">
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="monday"
                          v-model="form.monday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="monday">จันทร์</label>
                      </div>
                    </div>
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="tuesday"
                          v-model="form.tuesday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="tuesday">อังคาร</label>
                      </div>
                    </div>
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="wednesday"
                          v-model="form.wednesday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="wednesday">พุธ</label>
                      </div>
                    </div>
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="thurday"
                          v-model="form.thursday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="thurday">พฤหัสบดี</label>
                      </div>
                    </div>
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="friday"
                          v-model="form.friday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="friday">ศุกร์</label>
                      </div>
                    </div>
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="saturday"
                          v-model="form.saturday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="saturday">เสาร์</label>
                      </div>
                    </div>
                    <div
                      class="mb-2 col-12 col-sm-6 col-md-4 col-lg-3 category-check-item"
                    >
                      <div class="form-check">
                        <input
                          id="sunday"
                          v-model="form.sunday"
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                        />
                        <label for="sunday">อาทิตย์</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div
                  class="col-12 col-sm-9 offset-sm-3 col-md-8 offset-md-4 col-lg-9 offset-lg-3"
                >
                  <button class="btn btn-primary mr-3" type="submit">
                    {{ id ? "บันทึก" : "เพิ่ม" }}
                  </button>
                  <button
                    class="btn btn-danger"
                    @click="$router.push('/setting/lotto-type')"
                    type="button"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.color-picker {
  flex-wrap: nowrap;
  align-items: center;

  > span {
    line-height: 0;
  }
}
</style>

<style lang="scss" scoped>
input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(2);
  /* IE */
  -moz-transform: scale(2);
  /* FF */
  -webkit-transform: scale(2);
  /* Safari and Chrome */
  -o-transform: scale(2);
  /* Opera */
  transform: scale(2);
  padding: 10px;
}

.form-check {
  padding-left: 24px;
}
</style>

<script>
import SettingProvider from "@/resources/setting_provider"
import LottoProvider from "@/resources/lotto_provider"
const settingService = new SettingProvider()
const lottoService = new LottoProvider()

export default {
  data() {
    return {
      id: null,
      lottoCategoryTypes: [],
      form: {
        rule: "",
        name: "",
        huayGroupId: null,
        huayPayId: null,
        urlNews: "",
        closeTime: "",
        openTime: "",
        multiRoundPerDay: false,
        huayCategoryTypes: [],
        betBackgroundColor: "",
        betFontColor: "",
        betBorderColor: "",
      },
    }
  },
  computed: {
    lottoGroups: function () {
      return this.$store.state.lotto.lottoGroups
    },
    lottoCategories: function () {
      return this.$store.state.lotto.lottoCategories
    },
    lottoPays: function () {
      return this.$store.state.lotto.lottoPays
    },
  },
  watch: {
    lottoCategories: function () {
      this.initFormHuayCategoryTypes()
    },
    lottoCategoryTypes: function () {
      this.initFormHuayCategoryTypes()
    },
  },
  mounted() {
    this.getLottoGroupList()
    this.getLottoCategoryList()
    this.getLottoPayList()
    this.id = this.$route.query.id
    if (this.id) {
      this.getLottoTypeDetail()
    }
  },
  methods: {
    getLottoGroupList() {
      this.$store.dispatch("lotto/getAllLottoGroupList", {}, { root: true })
    },
    getLottoCategoryList() {
      this.$store.dispatch("lotto/getLottoCategoryList", {}, { root: true })
    },
    getLottoPayList() {
      this.$store.dispatch("lotto/getLottoPayList", {}, { root: true })
    },
    async getLottoTypeDetail() {
      this.swal.processing()
      const data = await lottoService.getLottoTypeDetail(this.id)
      if (data.error) {
        this.swal.swalError(
          data.error.message,
          function () {
            this.$router.push("/setting/lotto-type")
          }.bind(this)
        )
      } else {
        // this.form = data.result;
        this.initForm(data.result)
        this.swal.close()
      }
    },
    initForm(data) {
      this.form = data.huayType
      this.form.betBackgroundColor = data.huayType.betBackgroundColor
      this.lottoCategoryTypes = data.huayCategoryTypes
    },
    initFormHuayCategoryTypes() {
      this.form.huayCategoryTypes = []
      this.lottoCategories.forEach((cate) => {
        var item = this.lottoCategoryTypes.find(
          (ele) => ele.huayCategoryId == cate.id
        )
        if (item) {
          this.form.huayCategoryTypes.push({
            id: item.id,
            huayCategoryId: item.huayCategoryId,
            huayCategoryName: cate.name,
            huayTypeId: this.huayTypeId,
            status: item.status,
            isOpen: item.status == "OPEN",
          })
        } else {
          this.form.huayCategoryTypes.push({
            huayCategoryId: cate.id,
            huayCategoryName: cate.name,
            status: null,
            isOpen: false,
          })
        }
      })
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      if (this.form.everyMinute) {
        this.form.everyMinute = parseInt(this.form.everyMinute)
      }

      this.swal.processing()
      this.form.huayCategoryTypes.forEach((ele) => {
        ele.status = ele.isOpen ? "OPEN" : "CLOSE"
      })
      const res = !this.id
        ? await settingService.addLottoType(this.form)
        : await settingService.editLottoType(this.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.id ? "เพิ่มชนิดหวยสำเร็จ" : "แก้ไขชนิดหวยสำเร็จ",
          () => {
            this.$router.push("/setting/lotto-type")
          }
        )
      }
    },
  },
}
</script>
