<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['ตั้งค่า', 'งวดหวยตามประเภท', pageName]"
    />

    <div class="card border-primary mb-3">
      <h5
        class="
          card-header
          border-primary
          bg-primary
          text-white
          d-flex
          justify-content-between
          align-items-center
        "
      >
        {{ pageName }}
        <button
          class="btn btn-sm btn-danger mr-1"
          v-if="user.level == 'COMPANY'"
          @click="cancelBill(huayRound.id)"
        >
          ปิดงวด (ยกเลิกโพยทั้งหมด)
        </button>
      </h5>
      <div class="card-body">
        <loading v-show="isLoading" />
        <ValidationObserver ref="observer">
          <div v-show="!isLoading">
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">#ID:</div>
              <div class="form-group col-md-2">
                {{ form.huayRoundId }}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">กลุ่มหวย:</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <select class="form-control" v-model="form.huayGroupId">
                    <option
                      :value="item.id"
                      v-for="(item, index) in huayGroups"
                      :key="index"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">ชนิดหวย:</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <select class="form-control" v-model="form.huayTypeId">
                    <option
                      :value="item.id"
                      v-for="(item, index) in getHuayTypes()"
                      :key="index"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">ชื่องวด:</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.roundName"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">รอบที่:</div>
              <div class="form-group col-md-2">
                <input
                  type="number"
                  class="form-control"
                  v-model="form.roundNo"
                />
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">รูปสลาก:</div>
              <div class="form-group col-md-8">
                <ValidationProvider rules="" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.salakBackgroundUrl"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">วันที่เริ่ม:</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="th"
                    v-model="form.openDate"
                    placeholder="เลือกวัน"
                    label-no-date-selected="ยังไม่เลือก"
                    today-button
                    reset-button
                    close-button
                    label-close-button="ปิด"
                    label-reset-button="รีเซ็ต"
                    label-today="วันนี้"
                    label-today-button="วันนี้"
                  ></b-form-datepicker>
                  <input type="text" hidden v-model="form.openDate" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-md-1 text-md-right">เวลา</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <b-form-timepicker
                    locale="th"
                    v-model="form.openTime"
                    placeholder="เลือกเวลา"
                    now-button
                    reset-button
                    label-close-button="ปิด"
                    label-no-time-selected="ยังไม่เลือก"
                    label-reset-button="รีเซ็ต"
                    label-now-button="ตอนนี้"
                  ></b-form-timepicker>
                  <input type="text" hidden v-model="form.openTime" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">ปิดวันที่:</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    v-model="form.closeDate"
                    locale="th"
                    placeholder="เลือกวัน"
                    label-no-date-selected="ยังไม่เลือก"
                    today-button
                    reset-button
                    close-button
                    label-close-button="ปิด"
                    label-reset-button="รีเซ็ต"
                    label-today="วันนี้"
                    label-today-button="วันนี้"
                  ></b-form-datepicker>
                  <input type="text" hidden v-model="form.closeDate" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-md-1 text-md-right">เวลา</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <b-form-timepicker
                    locale="th"
                    v-model="form.closeTime"
                    placeholder="เลือกเวลา"
                    now-button
                    reset-button
                    label-close-button="ปิด"
                    label-no-time-selected="ยังไม่เลือก"
                    label-reset-button="รีเซ็ต"
                    label-now-button="ตอนนี้"
                  ></b-form-timepicker>
                  <input type="text" hidden v-model="form.closeTime" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
             <div class="row align-items-center">
              <div class="form-group col-md-2 text-md-right">ออกผลวันที่:</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    v-model="form.resultDate"
                    locale="th"
                    placeholder="เลือกวัน"
                    label-no-date-selected="ยังไม่เลือก"
                    today-button
                    reset-button
                    close-button
                    label-close-button="ปิด"
                    label-reset-button="รีเซ็ต"
                    label-today="วันนี้"
                    label-today-button="วันนี้"
                  ></b-form-datepicker>
                  <input type="text" hidden v-model="form.resultDate" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-md-1 text-md-right">เวลา</div>
              <div class="form-group col-md-2">
                <ValidationProvider rules="required" v-slot="v">
                  <b-form-timepicker
                    locale="th"
                    v-model="form.resultTime"
                    placeholder="เลือกเวลา"
                    now-button
                    reset-button
                    label-close-button="ปิด"
                    label-no-time-selected="ยังไม่เลือก"
                    label-reset-button="รีเซ็ต"
                    label-now-button="ตอนนี้"
                  ></b-form-timepicker>
                  <input type="text" hidden v-model="form.resultTime" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md">
                <button class="btn btn-danger mr-2" @click="$router.go(-1)">
                  ยกเลิก
                </button>
                <button class="btn btn-primary" @click="save">บันทึก</button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageName: this.$route.params.id ? "แก้ไขงวดหวย" : "เพิ่มงวดหวย",
      form: {
        huayGroupId: 0,
        huayTypeId: 0,
        roundName: "",
        openDate: "",
        openTime: "",
        closeDate: "",
        closeTime: "",
        resultDate: "",
        resultTime: "",
        salakBackgroundUrl: "",
      },
      mode: this.$route.params.id ? "update" : "create",
      huayRound: {},
      huayTypes: [],
      huayGroups: [],
      isLoading: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  methods: {
    async cancelBill(roundId) {
      let isConfirmed = await this.swal.confirm(
        `ท่านต้องการปิดงวดหวยพร้อมทั้งยกเลิกบิลทั้งหมด ใช่หรือไม่`,
        ""
      )
      if (!isConfirmed) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "patch",
        url: "setting/huay-round/cancel-bill/" + roundId,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "ปิดงวดหวยสำเร็จ",
          })
          this.$store.dispatch("auth/forceReload")
        })
        .catch((err) => {
          console.log(err)
          this.swal.swalError()
        })
    },
    getHuayTypes() {
      return this.huayTypes.filter(
        (item) => item.huayGroupId == this.form.huayGroupId
      )
    },
    async save() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      this.axios({
        method: this.mode == "create" ? "post" : "patch",
        url: "setting/huay-round",
        data: {
          id: this.form.huayRoundId,
          huayTypeId: this.form.huayTypeId,
          name: this.form.roundName,
          roundNo: parseInt(this.form.roundNo),
          salakBackgroundUrl: this.form.salakBackgroundUrl,
          openTime: this.$date(
            `${this.form.openDate} ${this.form.openTime}`
          ).format("YYYY-MM-DDTHH:mm:ssZ"),
          closeTime: this.$date(
            `${this.form.closeDate} ${this.form.closeTime}`
          ).format("YYYY-MM-DDTHH:mm:ssZ"),
          resultTime: this.$date(
            `${this.form.resultDate} ${this.form.resultTime}`
          ).format("YYYY-MM-DDTHH:mm:ssZ"),
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title:
              this.mode == "create" ? "เพิ่มงวดหวยสำเร็จ" : "แก้งวดหวยสำเร็จ",
          }).then(() => {
            if (this.mode == "create") {
              this.$router.push("/setting/lotto-period-by-date")
            }
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    async initData() {
      let id = this.$route.params.id ? "/" + this.$route.params.id : ""
      this.axios({
        method: "get",
        url: "setting/huay-round" + id,
      })
        .then((res) => {
          let data = res.data.data
          this.huayTypes = data.huayTypes
          this.huayGroups = data.huayGroups
          if (this.mode == "update") {
            this.huayRound = data.huayRound
            let huayType = data.huayTypes.find(
              (item) => item.id == this.huayRound.huayTypeId
            )
            this.form = {
              huayRoundId: this.huayRound.id,
              huayGroupId: huayType.huayGroupId,
              huayTypeId: this.huayRound.huayTypeId,
              roundName: this.huayRound.name,
              roundNo: this.huayRound.roundNo,
              salakBackgroundUrl: this.huayRound.salakBackgroundUrl,
              openDate: this.$date(this.huayRound.openTime).format(
                "YYYY-MM-DD"
              ),
              openTime: this.$date(this.huayRound.openTime).format("HH:mm"),
              closeDate: this.$date(this.huayRound.closeTime).format(
                "YYYY-MM-DD"
              ),
              closeTime: this.$date(this.huayRound.closeTime).format("HH:mm"),
              resultDate: this.$date(this.huayRound.resultTime).format(
                "YYYY-MM-DD"
              ),
              resultTime: this.$date(this.huayRound.resultTime).format("HH:mm"),
            }
          }
          // this.$refs.observer.reset();
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
  },
  mounted() {
    this.initData()
  },
}
</script>