<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="[
        'ตั้งค่า',
        {
          name: 'อัตราจ่าย',
          action: function () {
            $router.push('/setting/pay-rate')
          },
        },
        id ? 'แก้ไข' : 'เพิ่ม',
      ]"
    />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        {{ id ? "แก้ไขอัตราจ่าย" : "เพิ่มอัตราจ่าย" }}
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="submitForm"
          novalidate
        >
          <div class="form-group row">
            <label for="lotto-group-name" class="col-2 text-md-right my-auto">
              ชื่อ:
            </label>
            <div class="col-3">
              <ValidationProvider rules="required" v-slot="v">
                <input
                  id="lotto-group-name"
                  type="text"
                  class="form-control mr-1"
                  v-model="form.huayPay.name"
                  autocomplete="off"
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row">
            <label for="ordere" class="col-2 text-md-right my-auto">
              ลำดับการแสดง:
            </label>
            <div class="col-3">
              <ValidationProvider rules="required" v-slot="v">
                <input
                  id="ordere"
                  type="text"
                  class="form-control mr-1"
                  v-model.number="form.huayPay.showOrder"
                  autocomplete="off"
                  @keypress="$root.onlyNumber"
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table
                  class="
                    table table-bordered table-hover table-dark
                    text-center
                  "
                >
                  <thead>
                    <tr>
                      <th scope="col">ชื่อ</th>
                      <th scope="col">อัตราจ่าย</th>
                      <th scope="col">ส่วนลด</th>
                      <th scope="col">affiliate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in form.huayCategoryPays"
                      :key="item.huayCategoryId"
                    >
                      <th scope="row" style="vertical-align: top">
                        {{ item.categoryName }}
                      </th>
                      <td style="vertical-align: top">
                        <ValidationProvider
                          :rules="`required|max_value:999999999|min_value:0|numeric`"
                          v-slot="v"
                        >
                          <input
                            type="number"
                            class="form-control min-width-5"
                            v-model="item.payRate"
                            @keypress="$root.onlyNumber"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                      <td style="vertical-align: top">
                        <ValidationProvider
                          :rules="`required|max_value:999999999|min_value:0|numeric`"
                          v-slot="v"
                        >
                          <input
                            type="number"
                            class="form-control min-width-5"
                            v-model="item.discount"
                            @keypress="$root.onlyNumber"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                      <td style="vertical-align: top">
                        <ValidationProvider
                          :rules="`required|max_value:999999999|min_value:0|numeric`"
                          v-slot="v"
                        >
                          <input
                            type="number"
                            class="form-control min-width-5"
                            v-model="item.affiliate"
                            @keypress="$root.onlyNumber"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-danger mr-3"
                type="button"
                @click="$router.push('/setting/pay-rate')"
              >
                ยกเลิก
              </button>
              <button class="btn btn-primary" type="submit">
                {{ !id ? "เพิ่ม" : "บันทึก" }}
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import SettingProvider from "@/resources/setting_provider"
const settingService = new SettingProvider()
export default {
  data() {
    return {
      id: null,
      statuses: [
        {
          id: "OPEN",
          name: "เปิด",
        },
        {
          id: "CLOSE",
          name: "ปิด",
        },
      ],
      form: {
        huayPay: {
          name: "",
        },
        huayCategoryPays: [],
      },
    }
  },
  computed: {
    categories: function () {
      return this.$store.state.lotto.lottoCategories
    },
    isLoading: function () {
      return this.$store.state.lotto.isLottoCategoryLoading
    },
  },
  watch: {
    categories: function () {
      this.initForm()
    },
  },
  mounted() {
    this.getLottoCategoryList()
    this.id = this.$route.query.id
    if (this.id) {
      this.getPayRateDetail()
    }
  },
  methods: {
    initForm() {
      var categoryPays = []
      this.categories.forEach((ele) => {
        let huayCatePay = this.form.huayCategoryPays.find(
          (ele2) => ele2.huayCategoryId == ele.id
        )
        categoryPays.push({
          id: huayCatePay ? huayCatePay.id : null,
          huayCategoryId: ele.id,
          categoryName: ele.name,
          payRate: huayCatePay ? huayCatePay.payRate : null,
          discount: huayCatePay ? huayCatePay.discount : null,
          affiliate: huayCatePay ? huayCatePay.affiliate : null,
          status: "OPEN",
        })
      })
      this.form.huayCategoryPays = categoryPays
    },
    async getPayRateDetail() {
      this.swal.processing()
      const data = await settingService.getPayRateDetail(this.id)
      if (data.error) {
        this.swal.swalError(
          data.error.message,
          function () {
            this.$router.push("/setting/lotto-group")
          }.bind(this)
        )
      } else {
        this.form = data.result
        this.initForm()
        this.swal.close()
      }
    },
    getLottoCategoryList() {
      this.$store.dispatch("lotto/getLottoCategoryList", {}, { root: true })
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }
      this.swal.processing()
      const res = !this.id
        ? await settingService.addPayRate(this.form)
        : await settingService.editPayRate(this.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.id ? "เพิ่มอัตราจ่ายสำเร็จ" : "แก้ไขอัตราจ่ายสำเร็จ",
          () => {
            this.$router.push("/setting/pay-rate")
          }
        )
      }
    },
  },
}
</script>