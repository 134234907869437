<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'ชนิดหวย']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายการชนิดหวย
      </h5>
      <div class="card-body">
        <button class="btn btn-success text-white mb-2" @click="$router.push('/setting/lotto-type/add')" v-show="canEdit">
          เพิ่มชนิดหวย
        </button>
        <div class="table-responsive" v-if="!isLoading && items.length">
          <table class="
              table table-sm table-bordered table-hover table-dark
              text-center text-wrap
            ">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">ชื่อ</th>
                <th scope="col">กลุ่ม</th>
                <th>ลำดับการแสดง</th>
                <th>สถานะ</th>
                <th>เวลาเปิด</th>
                <th>เวลาปิด</th>
                <!-- <th>รูปภาพ</th> -->
                <th>icon</th>
                <th>หลายรอบ</th>
                <th>รอบละ(นาที)</th>
                <th>รอบละกี่วัน</th>
                <th>วันเปิด</th>
                <th>เวลาสร้าง</th>
                <th>แก้ไขล่าสุด</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ getLottoGroupName(item.huayGroupId) }}</td>
                <td>{{ item.showOrder }}</td>
                <td>{{ item.statusTH }}</td>
                <td>{{ item.openTime }}</td>
                <td>{{ item.closeTime }}</td>
                <!-- <th>{{ item.image }}</th> -->
                <th>{{ item.icon }}</th>
                <td>
                  <i class="fas fa-check" v-if="item.multiRoundPerDay"></i>
                </td>
                <td>
                  {{ item.everyMinute }}
                </td>
                <td>
                  {{ item.salakSellingDays }}
                </td>
                <td>{{ item.openDayTH }}</td>
                <td>
                  {{ $date(item.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  {{ $date(item.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td class="d-flex">
                  <router-link class="btn btn-primary" :class="{ disabled: !canEdit }" :to="{
                    path: '/setting/lotto-type/edit',
                    query: { id: item.id },
                  }">
                    <i class="fa fa-edit"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    items: function () {
      let types = this.$store.state.lotto.lottoTypes
      types.forEach((item) => {
        item.openDayTH = ""
        if (item.monday) {
          item.openDayTH += "จันทร์,"
        }
        if (item.tuesday) {
          item.openDayTH += "อังคาร,"
        }
        if (item.wednesday) {
          item.openDayTH += "พุธ,"
        }
        if (item.thursday) {
          item.openDayTH += "พฤหัสบดี,"
        }
        if (item.friday) {
          item.openDayTH += "ศุกร์,"
        }
        if (item.saturday) {
          item.openDayTH += "เสาร์,"
        }
        if (item.sunday) {
          item.openDayTH += "อาทิตย์,"
        }
        item.openDayTH = item.openDayTH.slice(0, -1)
      })
      return types
    },
    isLoading: function () {
      return this.$store.state.lotto.isLottoTypeLoading
    },
    lottoGroups: function () {
      return this.$store.state.lotto.lottoGroups
    },
    lottoPays: function () {
      return this.$store.state.lotto.lottoPays
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("lotto_type_list_edit")
    },
  },
  mounted() {
    this.getLottoGroupList()
    this.getLottoPayList()
    this.getLottoTypeList()
  },
  methods: {
    getLottoGroupList() {
      this.$store.dispatch("lotto/getAllLottoGroupList", {}, { root: true })
    },
    getLottoPayList() {
      this.$store.dispatch("lotto/getLottoPayList", {}, { root: true })
    },
    getLottoTypeList() {
      this.$store.dispatch("lotto/getLottoTypeList", {}, { root: true })
    },
    getLottoGroupName(lottoGroupId) {
      let item = this.lottoGroups.find((ele) => ele.id == lottoGroupId)
      return item ? item.name : ""
    },
  },
}
</script>