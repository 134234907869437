<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['แดชบอร์ด']" />
    <round-detail />
    <div class="card border-primary mb-3">
      <div
        class="
          card-header
          border-primary
          bg-primary
          text-white
          h5
          mb-0
          d-flex
          justify-content-between
        "
      >
        สรุปการแทง
        <button
          class="btn btn-sm"
          :class="[intervalFunc ? 'btn-danger' : 'btn-success']"
          @click="toggleInterval"
        >
          {{ intervalFunc ? "หยุด" : "เริ่ม" }} refresh
        </button>
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-12 d-flex flex-row flex-wrap">
            <div class="mx-1 mb-1">
              <button
                class="btn"
                :class="{
                  'active btn-success': !chartCategoryId,
                  'btn-primary': chartCategoryId,
                }"
                @click="chartCategoryId = ''"
              >
                ทั้งหมด
              </button>
            </div>
            <div class="mx-1 mb-1" v-for="cate in categories" :key="cate.id">
              <button
                class="btn"
                :class="{
                  'active btn-success': chartCategoryId == cate.id,
                  'btn-primary': chartCategoryId != cate.id,
                }"
                @click="chartCategoryId = cate.id"
              >
                {{ cate.name }}
              </button>
            </div>
          </div>
        </div>
        <bar-chart
          :chart-data="chartData"
          :options="chartOptions"
          style="max-height: 300px"
        />
        <div class="form-group row">
          <ValidationObserver
            ref="observer"
            class="w-100"
            tag="form"
            @submit.prevent="editLimitBuyAmount"
            v-if="!isLottoNumberSumsLoading"
            novalidate
          >
            <div class="d-flex col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 mt-2">
              <label for="lotto-category" class="mb-0" style="line-height: 36px"
                >จำกัด</label
              >
              <ValidationProvider
                :rules="`required|max_value:999999999|min_value:0|numeric`"
                class="mx-1"
                name="limitBuyAmount"
              >
                <input
                  type="number"
                  class="form-control"
                  @keypress="$root.onlyNumber"
                  v-model.number="limitBuyAmount"
                />
              </ValidationProvider>
              <button class="btn btn-primary">บันทึก</button>
            </div>
          </ValidationObserver>
        </div>
        <h4 class="my-4">
          เกินทั้งหมด: <span class="negative">{{ exceedAmountAll }} ฿</span>
        </h4>
        <table
          class="
            table table-dark table-bordered table-hover table-striped
            text-center
          "
          v-if="showOverLimitCategory"
        >
          <thead>
            <tr>
              <th>เลข</th>
              <th>ยอดที่เกิน</th>
              <th>ซื้อไปแล้ว</th>
              <th>ต้องซื้อเพิ่ม</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                number, ind
              ) in filteredOverLimitLottoNumberSumsByCategoryId(
                chartCategoryId
              )"
              :key="ind"
            >
              <td>{{ number.number }}</td>
              <td class="text-positive">{{ number.exceedAmount }} ฿</td>
              <td :class="{ 'text-positive': number.betExceed.buyAmount > 0 }">
                {{ number.betExceed.buyAmount }} ฿
              </td>
              <td
                :class="{
                  'text-positive': number.mustBuyAmount > 0,
                  'text-negative': number.mustBuyAmount < 0,
                }"
              >
                {{ number.mustBuyAmount }} ฿
              </td>
              <td>
                <div class="d-flex" v-if="number.mustBuyAmount > 0">
                  <input
                    type="text"
                    class="form-control mb-0 mr-1"
                    style="width: 100px"
                    v-model.number="number.currentBuyAmount"
                  />
                  <button
                    class="btn btn-primary"
                    @click="
                      setBuyAmount(number.betExceed, number.currentBuyAmount)
                    "
                  >
                    บันทึก
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex" v-if="showOverLimitAll">
          <div
            class="d-flex flex-column mr-3"
            v-for="cate in filteredCategoriesHaveOverLimit"
            :key="cate.id"
          >
            <table
              class="
                table table-dark table-bordered table-hover table-striped
                text-center
                w-100
              "
            >
              <thead>
                <th>ประเภท</th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ cate.name }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="
                table table-dark table-bordered table-hover table-striped
                text-center
              "
            >
              <thead>
                <th>เลข</th>
                <th>ยอดที่เกิน</th>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    number, ind
                  ) in filteredOverLimitLottoNumberSumsByCategoryId(cate.id)"
                  :key="ind"
                >
                  <td>{{ number.number }}</td>
                  <td class="text-positive">{{ number.exceedAmount }} ฿</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
input[type="radio"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.6); /* IE */
  -moz-transform: scale(1.6); /* FF */
  -webkit-transform: scale(1.6); /* Safari and Chrome */
  -o-transform: scale(1.6); /* Opera */
  transform: scale(1.6);
  padding: 10px;
}

.form-check {
  padding-left: 24px;
}
</style>

<script>
import BarChart from "@/components/chart/Bar";
import DashboardProvider from "@/resources/dashboard_provider";
const dashboardService = new DashboardProvider();

export default {
  name: "Dashboard",
  components: {
    BarChart,
  },
  data() {
    return {
      chartCategoryId: "",
      lottoNumberSums: [],
      betReceiveCategories: [],
      isLottoNumberSumsLoading: false,
      limitBuyAmount: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      intervalFunc: null,
    };
  },
  computed: {
    exceedAmountAll() {
      if (this.showOverLimitCategory) {
        let items = this.filteredOverLimitLottoNumberSumsByCategoryId(
          this.chartCategoryId
        );
        return items.reduce((total, arg) => total + arg.exceedAmount, 0);
      }
      let items = this.filteredOverLimitLottoNumberSums();
      return items.reduce((total, arg) => total + arg.exceedAmount, 0);
    },
    isCategoryLoading: function () {
      return this.$store.state.lotto.isLottoCategoryLoading;
    },
    categories: function () {
      return this.$store.state.lotto.lottoCategories;
    },
    isSomeLottoNumberSumOverLimit: function () {
      return this.lottoNumberSums.some(
        (ele) => ele.totalBuyAmount > this.limitBuyAmount
      );
    },
    roundId: function () {
      return this.$store.state.auth.roundId;
    },
    typeId: function () {
      return this.$store.state.auth.typeId;
    },
    filteredCategoriesHaveOverLimit: function () {
      return this.categories.filter(
        (ele) =>
          this.filteredOverLimitLottoNumberSumsByCategoryId(ele.id).length > 0
      );
    },
    showOverLimitCategory: function () {
      return (
        this.chartCategoryId != "" &&
        this.filteredOverLimitLottoNumberSumsByCategoryId(this.chartCategoryId)
          .length > 0
      );
    },
    showOverLimitAll: function () {
      return this.chartCategoryId == "" && this.isSomeLottoNumberSumOverLimit;
    },
    chartData: function () {
      var labels = [];
      var data = [];
      var backgroundColor = [];
      var limitData = [];
      var filteredNumberSums = this.lottoNumberSums.filter(
        (ele) =>
          ele.huayCategoryId == this.chartCategoryId ||
          this.chartCategoryId == ""
      );

      var maxTotalBuyAmount = Math.max.apply(
        Math,
        filteredNumberSums.map(function (o) {
          return o.totalBuyAmount;
        })
      );

      filteredNumberSums.forEach((ele) => {
        var category = this.categories.find(
          (cate) => cate.id == ele.huayCategoryId
        );
        var label =
          this.chartCategoryId == ""
            ? `${category ? category.name : ""} (${ele.number})`
            : ele.number;
        labels.push(label);
        data.push(ele.totalBuyAmount);
        backgroundColor.push(
          ele.totalBuyAmount > this.limitBuyAmount && this.limitBuyAmount
            ? "#dc3545"
            : "#ffc107"
        );
        if (this.limitBuyAmount) {
          limitData.push(this.limitBuyAmount);
        }
      });
      var datasets = [
        {
          label: "ยอดแทงรวม",
          backgroundColor: backgroundColor,
          data: data,
        },
      ];
      if (
        this.limitBuyAmount &&
        (maxTotalBuyAmount * 1.0) / this.limitBuyAmount > 0.2
      ) {
        datasets.push({
          label: "จำกัด",
          type: "line",
          borderColor: "#dc3545",
          data: limitData,
          fill: false,
          pointRadius: 0,
        });
      }
      return {
        labels: labels,
        datasets: datasets,
      };
    },
  },
  watch: {
    roundId: function () {
      this.getLottoNumberSumList();
    },
    chartCategoryId: function () {
      var categoryId = this.chartCategoryId || 0;
      var betReceiveCategory = this.betReceiveCategories.find(
        (ele) =>
          ele.huayTypeId == this.typeId && ele.huayCategoryId == categoryId
      );
      this.limitBuyAmount = betReceiveCategory ? betReceiveCategory.amount : 0;
    },
  },
  methods: {
    getLottoCategoryList() {
      this.$store.dispatch("lotto/getLottoCategoryList", {}, { root: true });
    },
    async setBuyAmount(betExceed, buyAmount) {
      betExceed.buyAmount += buyAmount;

      console.log(betExceed);

      this.swal.processing();
      try {
        await this.axios({
          method: "post",
          url: "dashboard/bet-exceed",
          data: betExceed,
        });
        this.swal.swalSuccess();
      } catch (err) {
        console.log(err);
        this.swal.swalError();
      }

      this.getLottoNumberSumList();
    },
    async getLottoNumberSumList() {
      if (!this.roundId) {
        return;
      }

      this.isLottoNumberSumsLoading = true;

      let promiseGetLottoNumberSumByRoundId =
        dashboardService.getLottoNumberSumByRoundId(this.roundId);
      let promiseGetBetExceed = dashboardService.getBetExceed(this.roundId);
      let [res, resBetExceed] = await Promise.all([
        promiseGetLottoNumberSumByRoundId,
        promiseGetBetExceed,
      ]);

      if (res.error) {
        this.swal.swalError(res.error.message);
      } else if (resBetExceed.error) {
        this.swal.swalError(resBetExceed.error.message);
      } else {
        let betExceed = resBetExceed.result;
        res.result.list.forEach((item) => {
          let itemBetExceed = betExceed?.find(
            (item2) =>
              item2.huayCategoryId == item.huayCategoryId &&
              item.number == item2.number
          );
          item.betExceed = itemBetExceed
            ? itemBetExceed
            : {
                huayCategoryId: item.huayCategoryId,
                number: item.number,
                huayRoundId: this.roundId,
                buyAmount: 0,
              };
        });

        this.lottoNumberSums = res.result.list;

        this.betReceiveCategories = res.result.betReceiveCategories;
        var categoryId = this.chartCategoryId || 0;
        var betReceiveCategory = this.betReceiveCategories.find(
          (ele) =>
            ele.huayTypeId == this.typeId && ele.huayCategoryId == categoryId
        );
        this.limitBuyAmount = betReceiveCategory
          ? betReceiveCategory.amount
          : 0;
      }

      this.isLottoNumberSumsLoading = false;
    },
    filteredOverLimitLottoNumberSumsByCategoryId(categoryId) {
      if (!this.limitBuyAmount) return [];
      let items = this.lottoNumberSums.filter(
        (ele) =>
          ele.huayCategoryId == categoryId &&
          ele.totalBuyAmount > this.limitBuyAmount
      );
      this.sort(items);
      return items;
    },
    filteredOverLimitLottoNumberSums() {
      if (!this.limitBuyAmount) return [];
      let items = this.lottoNumberSums.filter(
        (ele) => ele.totalBuyAmount > this.limitBuyAmount
      );
      this.sort(items);
      return items;
    },
    sort(items) {
      items.forEach((item) => {
        item.exceedAmount = item.totalBuyAmount - this.limitBuyAmount;
        item.mustBuyAmount = item.exceedAmount - item.betExceed.buyAmount;
      });
      items.sort(function (a, b) {
        return a.exceedAmount > b.exceedAmount ? 1 : -1;
      });
      items.sort(function (a, b) {
        return a.mustBuyAmount < b.mustBuyAmount ? 1 : -1;
      });
    },
    async editLimitBuyAmount() {
      let observer = this.$refs.observer;
      let isValid = await observer.validate();
      if (!isValid) {
        this.focusError(observer);
        return;
      }
      this.swal.processing();
      const res = await dashboardService.editBetReceiveCategory({
        huayTypeId: this.typeId,
        huayCategoryId: this.chartCategoryId || 0,
        amount: this.limitBuyAmount,
      });
      if (res.error) {
        this.swal.swalError(res.error.message);
      } else {
        this.swal.swalSuccess();
        this.getLottoNumberSumList();
      }
    },
    toggleInterval() {
      if (this.intervalFunc) {
        clearInterval(this.intervalFunc);
        this.intervalFunc = null;
      } else {
        this.intervalFunc = setInterval(() => {
          this.getLottoNumberSumList();
        }, 30 * 1000);
      }
    },
  },
  mounted() {
    this.getLottoCategoryList();
    this.getLottoNumberSumList();
  },
};
</script>