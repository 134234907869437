<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['รายการแทง', 'ดูของรวม / คาดคะเนได้เสีย']" />
    <round-detail />
    <div class="alert alert-info" role="alert">
      <span class="text-danger">ตัวเลขสีแดง</span> = เต็มแล้ว,
      <span class="text-success">พื้นหลังสีเขียว</span> = ถูกรางวัล, กด Ctrl+F
      เพื่อค้นหา
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive" v-show="!isLoading">
      <div class="d-flex align-items-center mb-2">
        <label for="viewMode" class="form-control-label mb-0 mr-2">ดู:</label>
        <select id="viewMode" class="form-control mr-2 w-auto" v-model="viewMode">
          <option :value="0">ของรวม</option>
          <option :value="1">คาดคะเนได้เสีย</option>
        </select>
        <label for="sortMode" class="form-control-label mb-0 mr-2">เรียง:</label>
        <select id="sortMode" class="form-control w-auto mr-2" v-model="sortMode">
          <option value="predict_desc">คาดคะเนยอดเสีย มาก-&gt;น้อย</option>
          <option value="predict_asc">คาดคะเนยอดเสีย น้อย-&gt;มาก</option>
          <option value="take_desc">ยอดแทง มาก-&gt;น้อย</option>
          <option value="take_asc">ยอดแทง น้อย-&gt;มาก</option>
          <option value="num_asc">หมายเลข น้อย-&gt;มาก</option>
          <option value="num_desc">หมายเลข มาก-&gt;น้อย</option>
        </select>
        <label for="numberOfItem" class="form-control-label mb-0 mr-2">จำนวนแถว:</label>
        <select id="numberOfItem" class="form-control w-auto mr-2" v-model.number="showNumberLength">
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">ทั้งหมด</option>
        </select>
        <b-button v-b-modal.modal-set-result variant="warning" v-if="isShowSetResult">ตั้งผลล่วงหน้า</b-button>
      </div>

      <ValidationObserver ref="observer">
        <table class="table table-sm table-bordered table-dark text-center">
          <thead>
            <tr>
              <th style="width: 150px"></th>
              <th v-for="item in categoryList" :key="item.id">
                {{ item.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>ซื้อ</span>
                  <span :class="getClassAmount(sumBuy)">
                    {{ util.formatMoney(sumBuy) }}
                  </span>
                </div>
              </td>
              <td class="bg-yellow" v-for="item in categoryList" :key="item.id" :class="getClassAmount(
                findBetItemWaitingStatementsByCategoryId(item.id)
                  .sumBuyAmount
              )
                ">
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumBuyAmount
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>ส่วนลด</span>
                  <span :class="getClassAmount(sumDiscountAmount)">
                    {{ util.formatMoney(sumDiscountAmount) }}
                  </span>
                </div>
              </td>
              <td class="bg-yellow" v-for="item in categoryList" :key="item.id" :class="getClassAmount(
                findBetItemWaitingStatementsByCategoryId(item.id)
                  .sumDiscountAmount
              )
                ">
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumDiscountAmount
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>ค่าแนะนำ</span>
                  <span :class="getClassAmount(sumAffiliateAmount)">
                    {{ util.formatMoney(sumAffiliateAmount) }}
                  </span>
                </div>
              </td>
              <td class="bg-yellow" v-for="item in categoryList" :key="item.id" :class="getClassAmount(
                findBetItemWaitingStatementsByCategoryId(item.id)
                  .sumAffiliateAmount
              )
                ">
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumAffiliateAmount
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>รับ</span>
                  <span :class="getClassAmount(sumReceive)">
                    {{ util.formatMoney(sumReceive) }}
                  </span>
                </div>
              </td>
              <td class="bg-yellow" v-for="item in categoryList" :key="item.id" :class="getClassAmount(
                findBetItemWaitingStatementsByCategoryId(item.id).sumReceive
              )
                ">
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id).sumReceive
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>จ่าย</span>
                  <span :class="getClassAmount(sumPay)">
                    {{ util.formatMoney(sumPay) }}
                  </span>
                </div>
              </td>
              <td class="bg-yellow" v-for="item in categoryList" :key="item.id" :class="getClassAmount(findSumPay(item))">
                {{ util.formatMoney(findSumPay(item)) }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <span>ตั้งสู้</span>
                  <button class="btn btn-sm btn-primary" @click="save" v-show="canEdit">
                    บันทึก
                  </button>
                </div>
              </td>
              <td v-for="item in categoryList" :key="item.id">
                <ValidationProvider :rules="`required|min_value:0`" v-slot="v">
                  <input type="text" class="form-control form-control-sm" @keypress="$root.onlyNumber"
                    v-model.number="findBetReceiveCategory(item.id).amount" :readonly="!canEdit" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </tbody>
          <tfoot class="bg-light" style="line-height: 5px" v-if="betItemWaitings">
            <tr v-for="index in showNumberLength" :key="index">
              <td class="text-black">{{ index }}</td>
              <template v-for="item in categoryList">
                <template v-for="(betItem, index) in [
                        findBetItemWaiting(index, item.id),
                      ]">
                  <td :key="item.id + index" :class="{ 'bg-success': betItem.sumPayAmountActual < 0 }">
                    <div class="d-flex justify-content-between">
                      <span class="text-danger font-weight-bold">
                        <a class="text-danger" href="#" @click.prevent="
                          $router.push(
                            `/items/of-number/${item.id}/${betItem.number}`
                          )
                          ">
                          {{ betItem.number }}
                        </a>
                      </span>
                      <span class="font-weight-bold" :class="{
                        'text-black':
                          !viewMode ||
                          (viewMode && parseFloat(betItem.sumPayAmount) == 0),
                        'text-danger':
                          (viewMode &&
                            parseFloat(betItem.sumPayAmount) < 0) ||
                          findBetReceiveCategory(item.id).amount <=
                          Math.ceil(betItem.sumBuyAmount),
                        'text-positive':
                          viewMode && betItem.sumPayAmountActual < 0,
                        'text-success':
                          viewMode && parseFloat(betItem.sumPayAmount) > 0,
                      }">
                        {{
                          viewMode
                          ? betItem.sumPayAmount
                            ? util.formatMoneyNormal(
                              Math.ceil(betItem.sumPayAmount)
                            )
                            : ""
                          : betItem.sumBuyAmount
                            ? util.formatMoneyNormal(
                              Math.ceil(betItem.sumBuyAmount)
                            )
                            : ""
                        }}
                      </span>
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </tfoot>
        </table>
      </ValidationObserver>
    </div>
    <b-modal id="modal-set-result" body-bg-variant="dark" body-text-variant="light" header-bg-variant="dark"
      header-text-variant="light" footer-bg-variant="dark" footer-text-variant="light" hide-footer title="ตั้งผลล่วงหน้า">
      <ValidationObserver ref="observer" tag="form" @submit.prevent="setResult" novalidate>
        <ValidationProvider :rules="`required|numeric|min:3`" v-slot="v">
          <div class="form-group">
            <label for="id">3ตัวบน</label>
            <input type="text" class="form-control" id="id" v-model="formSetResult.back3Top" />
          </div>
          <span class="text-danger">{{ v.errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider :rules="`required|numeric|min:2`" v-slot="v">
          <div class="form-group">
            <label for="id">2ตัวล่าง</label>
            <input type="text" class="form-control" id="id" v-model="formSetResult.back2Bottom" />
          </div>
          <span class="text-danger">{{ v.errors[0] }}</span>
        </ValidationProvider>
        <button class="btn btn-primary" type="submit">ตั้งผล</button>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style scoped>
.min-width-th {
  min-width: 150px;
}

tfoot>tr>td {
  padding: 0.65rem !important;
}
</style>

<script>
export default {
  data() {
    return {
      sortMode: "predict_desc",
      viewMode: 0, // 0 = ดูของ, 1 คาดคะเนได้เสีย
      sumBuy: 0.0,
      sumDiscountAmount: 0.0,
      sumAffiliateAmount: 0.0,
      sumReceive: 0.0,
      sumPay: 0.0,
      isLoading: false,
      betItemWaitings: [],
      betItemWaitingStatements: [],
      betItemWaitingMaxBuys: [],
      categoryList: [],
      showNumberLength: 100,
      betReceiveCategorys: [],
      betItemWaitingsByCategory: new Map(),
      maxPayByCategoryId: new Map(),
      formSetResult: {
        back3Top: "",
        back2Bottom: "",
      },
      isShowSetResult: false,
    }
  },
  methods: {
    findBetReceiveCategory(categoryId) {
      let item = this.betReceiveCategorys.find(
        (item) => item.huayCategoryId == categoryId
      )
      return item
        ? item
        : {
          amount: 0,
          huayCategoryId: categoryId,
        }
    },
    findBetItemWaiting(index, categoryId) {
      let items = this.betItemWaitingsByCategory.get(categoryId)

      if (!items || index > items.length) {
        return {}
      }

      // if (items.length > this.showNumberLength) {
      //   this.showNumberLength = items.length
      // }

      return items[index - 1]
    },
    findSumPay(item) {
      return this.maxPayByCategoryId.get(item.id)
    },
    findMaxPayByCategoryId(categoryId) {
      let min = Math.min.apply(
        Math,
        this.betItemWaitings
          ?.filter((item) => item.categoryId === categoryId)
          .map(function (item) {
            return item.sumPayAmount
          })
      )

      return isFinite(min) ? min : 0
    },
    findBetItemWaitingStatementsByCategoryId(categoryId) {
      let item = this.betItemWaitingStatements?.find(
        (item) => item.categoryId == categoryId
      )
      return item
        ? item
        : {
          sumBuyAmount: 0.0,
          sumDiscountAmount: 0.0,
          sumAffiliateAmount: 0.0,
          sumReceive: 0.0,
        }
    },
    findBetItemWaitingMaxBuysByCategoryId(categoryId) {
      let item = this.betItemWaitingMaxBuys?.find(
        (item) => item.categoryId == categoryId
      )
      return item
        ? item
        : {
          maxPay: 0.0,
        }
    },
    getClassAmount(a) {
      if (parseFloat(a) == parseFloat(0)) {
        return ""
      }
      if (parseFloat(a) > parseFloat(0)) {
        return "positive"
      }
      return "negative"
    },
    async setResult() {
      let result = await this.swal.confirm("ยืนยันการตั้งผลล่วงหน้า")
      if (!result) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "huay/result/prepare",
          data: {
            huayRoundId: this.roundId,
            back3Top: this.formSetResult.back3Top,
            back2Bottom: this.formSetResult.back2Bottom,
          },
        })

        this.swal.success()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
    async loadData() {
      this.isLoading = true
      try {
        let [res1, res2, res3] = await Promise.all([
          this.axios({
            method: "post",
            url: "bet/limit/statement",
            data: {
              huayRoundId: this.roundId,
            },
          }),
          this.axios.get("huay/category/list"),
          this.axios.get(
            "bet/limit-by-category/" + this.$store.state.auth.typeId
          ),
        ])

        this.betReceiveCategorys = res3.data.data

        let data = res1.data.data

        this.isShowSetResult = data.isShowSetResult
        this.formSetResult.back3Top = data.resultBack3Top
        this.formSetResult.back2Bottom = data.resultBack2Bottom

        this.categoryList = res2.data.data.list.filter(item => [
          'BACK_3_TOP',
          'BACK_3_TOP_CROSS',
          'BACK_2_TOP',
          'BACK_2_BOTTOM',
          'RUN_BACK_TOP',
          'RUN_BACK_BOTTOM',
        ].includes(item.code))

        let betItemWaitingStatements = []
        this.categoryList?.forEach((item) => {
          let betItemWaitings = data.betItemWaitings?.filter(
            (item2) => item2.categoryId == item.id
          )
          if (betItemWaitings) {
            let betItemWaitingStatement = {
              categoryId: item.id,
              sumBuyAmount: 0.0,
              sumDiscountAmount: 0.0,
              sumAffiliateAmount: 0.0,
              sumReceive: 0.0,
            }

            betItemWaitings.forEach((item2) => {
              betItemWaitingStatement.sumBuyAmount += parseFloat(
                item2.sumBuyAmount
              )
              betItemWaitingStatement.sumDiscountAmount += parseFloat(
                item2.sumDiscountAmount
              )
              betItemWaitingStatement.sumAffiliateAmount += parseFloat(
                item2.sumAffiliateAmount
              )
              betItemWaitingStatement.sumReceive =
                betItemWaitingStatement.sumBuyAmount +
                betItemWaitingStatement.sumDiscountAmount +
                betItemWaitingStatement.sumAffiliateAmount
            })

            this.sumBuy += parseFloat(betItemWaitingStatement.sumBuyAmount)
            this.sumDiscountAmount += parseFloat(
              betItemWaitingStatement.sumDiscountAmount
            )
            this.sumAffiliateAmount += parseFloat(
              betItemWaitingStatement.sumAffiliateAmount
            )
            betItemWaitingStatements.push(betItemWaitingStatement)
          }
        })

        data.betItemWaitings?.forEach((item) => {
          let betItemWaitingStatement = betItemWaitingStatements.find(
            (item2) => item2.categoryId === item.categoryId
          )
          if (betItemWaitingStatement) {
            item.sumPayAmount =
              parseFloat(item.sumPayAmount) + betItemWaitingStatement.sumReceive
          }
        })

        this.sumReceive =
          this.sumBuy + this.sumDiscountAmount + this.sumAffiliateAmount

        this.betItemWaitingStatements = betItemWaitingStatements

        this.betItemWaitings = data.betItemWaitings
        this.betItemWaitingMaxBuys = data.betItemWaitingMaxBuys

        this.categoryList?.forEach((item) => {
          let sumPay = this.findMaxPayByCategoryId(item.id)

          this.maxPayByCategoryId.set(item.id, sumPay)
          this.sumPay += sumPay

          let betItems = this.betItemWaitings?.filter(
            (item2) => item2.categoryId === item.id
          )

          betItems?.sort(function (a, b) {
            return parseFloat(a.sumPayAmount) > parseFloat(b.sumPayAmount)
              ? 1
              : -1
          })

          this.betItemWaitingsByCategory.set(item.id, betItems)
        })

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async save() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "bet/limit-by-category",
          data: this.betReceiveCategorys,
        })

        await this.$swal({
          icon: "success",
          text: "ตั้งสู้สำเร็จ",
        })

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)

        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }
    },
  },
  computed: {
    roundId() {
      return this.$store.state.auth.roundId
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("item_overall_edit")
    }

  },
  watch: {
    roundId() {
      this.loadData()
    },
    sortMode() {
      if (this.sortMode == "predict_desc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumPayAmount) > parseFloat(b.sumPayAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "predict_asc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumPayAmount) < parseFloat(b.sumPayAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "take_desc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumBuyAmount) < parseFloat(b.sumBuyAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "take_asc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumBuyAmount) > parseFloat(b.sumBuyAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "num_asc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseInt(a.number) > parseInt(b.number) ? 1 : -1
          })
        })
      } else if (this.sortMode == "num_desc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseInt(a.number) < parseInt(b.number) ? 1 : -1
          })
        })
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
