<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['การเงิน', 'เงินเข้าออก']" />
    <button class="btn btn-primary" @click="openModalForm()" v-show="canEdit">
      สร้างยอด
    </button>
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <CDataTable
        :items="items"
        :fields="fields"
        :column-filter="{ external: true }"
        :items-per-page-select="{ label: 'จำนวนแถว' }"
        :table-filter-value.sync="tableFilterValue"
        :column-filter-value.sync="columnFilterValue"
        :sorter-value.sync="sorterValue"
        :items-per-page.sync="itemsPerPage"
        :loading="tableLoading"
        :noItemsView="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }"
        v-on:pagination-change="paginationChange"
        v-on:update:column-filter-value="columnFilter"
        hover
        sorter
        dark
        border
        size="sm"
        addTableClasses="w-100 text-wrap"
      >
      </CDataTable>
      <CPagination
        :active-page="activePage"
        :double-arrows="false"
        :pages="totalPage"
        :dots="false"
        v-on:update:activePage="updateActivePage"
      />
    </div>

    <b-modal
      id="modal-form"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      title="สร้างยอด"
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="submit"
        novalidate
      >
        <div class="form-group">
          <label for="userId"><i class="fas fa-user"></i> #สมาชิก</label>
          <ValidationProvider rules="required|numeric" v-slot="v">
            <input
              id="userId"
              v-model.number="form.userId"
              type="text"
              class="form-control"
              placeholder="#สมาชิก"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label for="amount"
            >จำนวนเงินที่ฝากเข้ามา (ใส่เป็นจำนวนเงินบาท)</label
          >
          <ValidationProvider :rules="`required|min_value:0`" v-slot="v">
            <input
              type="text"
              class="form-control"
              id="amount"
              placeholder="0.0"
              v-model="form.amount"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>วันที่</label>
          <ValidationProvider :rules="`required`" v-slot="v">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="form.date"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>เวลา</label>
          <ValidationProvider
            :rules="{
              required: true,
            }"
            v-slot="v"
          >
            <input
              class="form-control"
              type="text"
              placeholder="00:00"
              v-model="formTime"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label for="bankCode">ธนาคาร</label>
          <ValidationProvider :rules="`required`" v-slot="v">
            <select id="bankCode" class="form-control" v-model="form.bankCode">
              <option value="KOEX">Hana Bank</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <template #modal-footer>
        <button class="btn btn-danger mr-2" @click="hideModal">ยกเลิก</button>
        <button class="btn btn-primary" @click="submit">ตกลง</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          sorter: false,
        },
        {
          key: "userTransferRequestId",
          label: "#แจ้งฝากถอน",
          sorter: false,
        },
        {
          key: "bankAccount",
          label: "รายละเอียด",
          sorter: false,
          filter: false,
        },
        {
          key: "amount",
          label: "จำนวน",
          sorter: false,
        },
        // {
        //   key: "balance",
        //   label: "เงินคงเหลือ",
        //   filter: false,
        //   sorter: false,
        // },
        {
          key: "bankStatementCode",
          label: "ธนาคาร",
          sorter: false,
        },
        {
          key: "date",
          label: "วันที่",
          sorter: false,
        },
        {
          key: "time",
          label: "เวลา",
          sorter: false,
        },
        {
          key: "createdAt",
          label: "เข้าเมื่อ",
          filter: false,
          sorter: false,
        },
        {
          key: "statusTH",
          label: "สถานะ",
          filter: false,
          sorter: false,
        },
      ],
      items: [],
      isLoading: true,
      tableFilterValue: "",
      columnFilterValue: {},
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
      form: {
        amount: null,
        bankCode: null,
        date: null,
        time: null,
      },
      formTime: null,
      defaultDepositBankCode: "",
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "bank_statement_edit"
      )
    },
  },
  watch: {
    formTime(newVal, oldVal) {
      if (newVal?.length === 2 && !oldVal?.includes(":")) {
        this.formTime += ":"
      }

      if (newVal?.length > 5) {
        this.formTime = oldVal
      }
    },
  },
  methods: {
    openModalForm() {
      this.form.amount = null
      this.form.bankCode = this.defaultDepositBankCode
      this.form.date = this.$date().format("YYYY-MM-DD")
      this.form.time = ""

      this.$bvModal.show("modal-form")
    },
    hideModal() {
      this.$bvModal.hide("modal-form")
    },
    async submit() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      let confirm = await this.swal.confirm(
        "สร้างยอด",
        "ท่านต้องการสร้างยอดใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.form.time = this.formTime

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "credit/bank/statement/add",
          data: this.form,
        })

        this.swal.swalSuccess()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError(e.response.data.description)
      }
    },
    getAmountClass(amount) {
      if (amount > 0) {
        return "text-positive"
      } else if (amount < 0) {
        return "text-negative"
      } else {
        return ""
      }
    },
    loadData() {
      this.tableLoading = true
      this.axios({
        method: "get",
        url: "credit/bank/statement",
        params: {
          ...this.columnFilterValue,
          page: this.activePage,
          pageSize: this.itemsPerPage,
        },
      })
        .then((res) => {
          let data = res.data.data
          data.data.forEach((item) => {
            item._cellClasses = {
              amount: this.getAmountClass(parseFloat(item.amount)),
              balance: parseFloat(item.balance) > 0 ? "text-positive" : "",
              statusTH:
                item.status == "SUCCESS" ? "text-positive" : "text-negative",
            }

            item.amount = this.util.formatMoney(item.amount)
            item.balance = this.util.formatMoney(item.balance)

            item.createdAt = this.$date(item.createdAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          })
          // this.totalPage = data.totalPage;
          this.items = data.data
          this.isLoading = false
          this.tableLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    paginationChange(val) {
      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.items &&
        this.items.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
    async getDepositBankCode() {
      this.swal.processing()

      try {
        const res = await this.axios({
          method: "get",
          url: "bank/deposit",
        })

        this.defaultDepositBankCode = res.data.data

        this.swal.close()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
  async mounted() {
    await this.getDepositBankCode()
    this.loadData()
  },
}
</script>
